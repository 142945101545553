exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-templates-about-credence-jsx": () => import("./../../../src/templates/about-credence.jsx" /* webpackChunkName: "component---src-templates-about-credence-jsx" */),
  "component---src-templates-become-partner-jsx": () => import("./../../../src/templates/become-partner.jsx" /* webpackChunkName: "component---src-templates-become-partner-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-case-studies-jsx": () => import("./../../../src/templates/case-studies.jsx" /* webpackChunkName: "component---src-templates-case-studies-jsx" */),
  "component---src-templates-case-studies-slug-jsx": () => import("./../../../src/templates/case-studies-slug.jsx" /* webpackChunkName: "component---src-templates-case-studies-slug-jsx" */),
  "component---src-templates-clients-jsx": () => import("./../../../src/templates/clients.jsx" /* webpackChunkName: "component---src-templates-clients-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-credence-leadership-team-jsx": () => import("./../../../src/templates/credence-leadership-team.jsx" /* webpackChunkName: "component---src-templates-credence-leadership-team-jsx" */),
  "component---src-templates-default-template-jsx": () => import("./../../../src/templates/default-template.jsx" /* webpackChunkName: "component---src-templates-default-template-jsx" */),
  "component---src-templates-events-jsx": () => import("./../../../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-events-slug-jsx": () => import("./../../../src/templates/events-slug.jsx" /* webpackChunkName: "component---src-templates-events-slug-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-industries-jsx": () => import("./../../../src/templates/industries.jsx" /* webpackChunkName: "component---src-templates-industries-jsx" */),
  "component---src-templates-industries-slug-jsx": () => import("./../../../src/templates/industries-slug.jsx" /* webpackChunkName: "component---src-templates-industries-slug-jsx" */),
  "component---src-templates-job-openings-vacancy-jsx": () => import("./../../../src/templates/job-openings-vacancy.jsx" /* webpackChunkName: "component---src-templates-job-openings-vacancy-jsx" */),
  "component---src-templates-job-openings-vacancy-slug-jsx": () => import("./../../../src/templates/job-openings-vacancy-slug.jsx" /* webpackChunkName: "component---src-templates-job-openings-vacancy-slug-jsx" */),
  "component---src-templates-life-at-credence-jsx": () => import("./../../../src/templates/life-at-credence.jsx" /* webpackChunkName: "component---src-templates-life-at-credence-jsx" */),
  "component---src-templates-partners-jsx": () => import("./../../../src/templates/partners.jsx" /* webpackChunkName: "component---src-templates-partners-jsx" */),
  "component---src-templates-press-releases-jsx": () => import("./../../../src/templates/press-releases.jsx" /* webpackChunkName: "component---src-templates-press-releases-jsx" */),
  "component---src-templates-press-releases-slug-jsx": () => import("./../../../src/templates/press-releases-slug.jsx" /* webpackChunkName: "component---src-templates-press-releases-slug-jsx" */),
  "component---src-templates-products-jsx": () => import("./../../../src/templates/products.jsx" /* webpackChunkName: "component---src-templates-products-jsx" */),
  "component---src-templates-products-slug-jsx": () => import("./../../../src/templates/products-slug.jsx" /* webpackChunkName: "component---src-templates-products-slug-jsx" */),
  "component---src-templates-services-slug-jsx": () => import("./../../../src/templates/services-slug.jsx" /* webpackChunkName: "component---src-templates-services-slug-jsx" */),
  "component---src-templates-testimonials-jsx": () => import("./../../../src/templates/testimonials.jsx" /* webpackChunkName: "component---src-templates-testimonials-jsx" */),
  "component---src-templates-whitepapers-jsx": () => import("./../../../src/templates/whitepapers.jsx" /* webpackChunkName: "component---src-templates-whitepapers-jsx" */),
  "component---src-templates-why-credence-jsx": () => import("./../../../src/templates/why-credence.jsx" /* webpackChunkName: "component---src-templates-why-credence-jsx" */)
}

